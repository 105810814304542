import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';

export default function ListTraders() {
    
    const [traders, settraders] = useState(null);
    const [error, setErros] = useState('');

    const { isLoading: isLoadingtraders, refetch: gettraders } = useQuery(
        "list-traders",
        async () => {
          return await apiClient.get("/api/show-traders");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                settraders(res.data.traders);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }
          },
        }
      );
    
      useEffect(() => {
        gettraders() 
        if (isLoadingtraders) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        
        {isLoadingtraders? 'Loading traders...' : 'List of Traders'}
      </Typography>
    <TableContainer component={Paper}>
        
      {traders !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        
        <TableCell>Name</TableCell>
          <TableCell align="right">Level</TableCell>
          <TableCell align="right">Commision</TableCell>
          <TableCell align="right">View/Edit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {traders.map((trader) => (
          <TableRow
            key={trader.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {trader.trader_name}
            </TableCell>
            <TableCell align="right">{trader.level}</TableCell>
            <TableCell align="right">{trader.commission}</TableCell>
            <TableCell align="right"><Button component={RouterLink} to={`/edit-trader/${trader.id}`} startIcon={<EditIcon />}>View/Edit</Button></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>
    </div>
  );
}