import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import WineBarIcon from '@mui/icons-material/WineBar';
import AppleIcon from '@mui/icons-material/Apple';
import CookieIcon from '@mui/icons-material/Cookie';
import TapasIcon from '@mui/icons-material/Tapas';
import KebabDiningIcon from '@mui/icons-material/KebabDining';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import Badge from '@mui/material/Badge';
import DiningIcon from '@mui/icons-material/Dining';
import { useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import TelegramIcon from '@mui/icons-material/Telegram';

export default function Offline() {

  return (
        <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Home`} />
                  </ListItemButton>
              </ListItem>
            </Link>            
          </List>

          <Divider />
          <List>
          <Link  to='/subscriptions' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CardMembershipIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Subscription`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            

            <Link  to='/login' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <LoginIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Login`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Divider/>
            <Link  href='https://t.me/+i92jBqMvNh1iN2Qx' underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <TelegramIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Telegram`} />
                </ListItemButton>
            </ListItem>
          </Link>
          </List>
    </Fragment>
  );
}