import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const EditTrade = () => {
    const params = useParams();

    const [trade, settrades] = useState(null);
    const { isLoading: isLoadingtrades, refetch: getTrade } = useQuery(
        "edit-trade",
        async () => {
          return await apiClient.get(`/api/edit-trade/${params.id}`);
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                settrades(res.data.trade);
                setPlanForm({ ...planForm,
                    trade_type : res.data.trade.trade_type,
                    trader_id : res.data.trade.trader_id,
                    status : res.data.trade.status,
                    open_time : res.data.trade.open_time,
                    close_time:res.data.trade.close_time,
                    open_price : res.data.trade.open_price,
                    close_price : res.data.trade.close_price,
                    order_id : res.data.trade.order_id,
                    commission :res.data.trade.commission,
                    currency :res.data.trade.currency,
                    amount :res.data.trade.amount,
                 });
            }
          },
          onError: (err) => {
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }
          },
        }
      );


    const [traders, settraders] = useState(null);
    const { isLoading: isLoadingtraders, refetch: gettraders } = useQuery(
        "fetch-traders",
        async () => {
          return await apiClient.get("/api/show-traders");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                settraders(res.data.traders);
            }
          },
          onError: (err) => {
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }
          },
        }
      );

      
    const dispatch = useDispatch();
    
    const initialLogin = {
        trade_type : ''	,
        trader_id : ''	,
        status : ''	,
        open_time : ''	,
        close_time: '',
        open_price : ''	,
        close_price : ''	,
        order_id : ''	,
        commission : '',
        currency : '',
        amount : '',
    }
    
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);

    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });
    const initialImage = {
        image: ''
    }
   
    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
            const formData = new FormData();
            formData.append('trade_id' , params.id	)
            formData.append('trade_type' , planForm.trade_type	)
            formData.append('trader_id' , planForm.trader_id	)
            formData.append('status' , planForm.status	)
            formData.append('open_time' , planForm.open_time	)
            formData.append('open_price' , planForm.open_price	)
            formData.append('close_price' , planForm.close_price	)
            formData.append('order_id' , planForm.order_id	)
            formData.append('commission' , planForm.commission)
            formData.append('close_time', planForm.close_time)
            formData.append('currency', planForm.currency)
            formData.append('amount', planForm.amount)

          return await apiClient.post(`/api/update-trade`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }   
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 

      useEffect(() => {
        gettraders();
        getTrade();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>

        {(trade === null || traders === null) ? 'Loading Trade...': 
        
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        EDIT TRADE
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to edit trades
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="trader_id">Select Trader</InputLabel>
                      {(error !== '' && error.trader_id) ?
                          <Select
                              error
                              onChange={onChange}
                              labelId="trader_id"
                              id="trader_id"
                              name="trader_id"
                              defaultValue={trade.trader_id}
                              label={`Select Trader`}
                              helperText={error.trader_id}
                              >
                              <MenuItem selected value=''>
                                  Select Trader
                              </MenuItem>
                                {traders.map(trader => (
                                    <MenuItem key={trader.id} selected value={trader.id}>
                                    {trader.trader_name}
                                    </MenuItem> 
                                ))}
                                   
                              
                          </Select>
                      :
                          <Select
                              onChange={onChange}
                              labelId="trader_id"
                              name="trader_id"
                              defaultValue={trade.trader_id}
                              id="trader_id"
                              label={`Select Trader`}
                              >
                              <MenuItem selected value="">
                                  Select Trader
                              </MenuItem>     

                              {traders.map(trader => (
                                    <MenuItem key={trader.id} selected value={trader.id}>
                                    {trader.trader_name}
                                    </MenuItem> 
                                ))}
                          </Select>
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.trader_id}
                      </Typography>

                  </FormControl>

                  
                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="status">Select Trade status</InputLabel>
                      {(error !== '' && error.status) ?
                          <Select
                              error
                              onChange={onChange}
                              labelId="status"
                              id="status"
                              name="status"
                              defaultValue={trade.status}
                              label={`Select Trade status`}
                              helperText={error.status}
                              >
                              <MenuItem selected value=''>
                                  Select Trade status
                              </MenuItem>

                              <MenuItem selected value='open'>
                              open
                              </MenuItem> 

                              <MenuItem selected value='close'>
                              close
                              </MenuItem> 
                            
                          </Select>
                      :
                          <Select
                              onChange={onChange}
                              labelId="status"
                              name="status"
                              defaultValue={trade.status}
                              id="status"
                              label={`Select Trade status`}
                              >
                              <MenuItem selected value="">
                                  Select Trade status
                              </MenuItem>     

                              <MenuItem selected value='open'>
                              open
                              </MenuItem> 

                              <MenuItem selected value='close'>
                              close
                              </MenuItem> 
                            
                          </Select>
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.status}
                      </Typography>
                  </FormControl>

                  <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="trade_type">Select Trade Type</InputLabel>
                      {(error !== '' && error.trade_type) ?
                          <Select
                              error
                              onChange={onChange}
                              labelId="trade_type"
                              id="trade_type"
                              name="trade_type"
                              defaultValue={trade.trade_type}
                              label={`Select Trade Type`}
                              helperText={error.trade_type}
                              >
                              <MenuItem selected value=''>
                                  Select Trade Type
                              </MenuItem>

                              <MenuItem selected value='buy'>
                              buy
                              </MenuItem> 

                              <MenuItem selected value='sell'>
                              sell
                              </MenuItem> 
                            
                          </Select>
                      :
                          <Select
                              onChange={onChange}
                              labelId="trade_type"
                              name="trade_type"
                              defaultValue={trade.trade_type}
                              id="trade_type"
                              label={`Select Trade Type`}
                              >
                              <MenuItem selected value="">
                                  Select Trade Type
                              </MenuItem>     

                              <MenuItem selected value='buy'>
                              buy
                              </MenuItem> 

                              <MenuItem selected value='sell'>
                              sell
                              </MenuItem> 
                            
                          </Select>
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.trade_type}
                      </Typography>
                  </FormControl>


                        

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="open_time">Open Time</InputLabel>
                            {(error !== '' && error.open_time) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="open_time"
                            type={'datetime-local'}
                            name="open_time"
                            defaultValue={trade.open_time}
                            label="Open Time"
                            helperText={error.open_time}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="open_time"
                            type={'datetime-local'}
                            name="open_time"
                            defaultValue={trade.open_time}
                            label="Open Time"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.open_time}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="close_time">Close Time</InputLabel>
                          {(error !== '' && error.close_time) ?

                          <OutlinedInput
                          onChange={onChange}
                          error
                          id="close_time"
                          type={'datetime-local'}
                          name="close_time"
                          defaultValue={trade.close_time}
                          label="Close Time"
                          helperText={error.close_time}
                          />
                          :
                          <OutlinedInput
                          onChange={onChange}
                          id="close_time"
                          type={'datetime-local'}
                          name="close_time"
                          defaultValue={trade.close_time}
                          label="Close Time"
                          />
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.close_time}
                          </Typography>
                      </FormControl>

                      {/* <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="open_price">Open Price</InputLabel>
                            {(error !== '' && error.open_price) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="open_price"
                            type={'text'}
                            name="open_price"
                            defaultValue={trade.open_price}
                            label="Open Price"
                            helperText={error.open_price}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="open_price"
                            type={'text'}
                            name="open_price"
                            defaultValue={trade.open_price}
                            label="Open Price"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.open_price}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="close_price">Close Price</InputLabel>
                            {(error !== '' && error.close_price) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="close_price"
                            type={'text'}
                            name="close_price"
                            defaultValue={trade.close_price}
                            label="Close Price"
                            helperText={error.close_price}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="close_price"
                            type={'text'}
                            name="close_price"
                            defaultValue={trade.close_price}
                            label="Close Price"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.close_price}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="order_id">Order ID</InputLabel>
                            {(error !== '' && error.order_id) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="order_id"
                            type={'text'}
                            name="order_id"
                            defaultValue={trade.order_id}
                            label="Order ID"
                            helperText={error.order_id}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="order_id"
                            type={'text'}
                            name="order_id"
                            defaultValue={trade.order_id}
                            label="Order ID"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.order_id}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="commission">Commission</InputLabel>
                            {(error !== '' && error.commission) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="commission"
                            name="commission"
                            defaultValue={trade.commission}
                            type={'text'}
                            label="Commission"
                            helperText={error.commission}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="commission"
                            name="commission"
                            defaultValue={trade.commission}
                            type={'text'}
                            label="Commission"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.commission}
                            </Typography>
                        </FormControl> */}

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="currency">currency (eg: EUR/USD)</InputLabel>
                            {(error !== '' && error.currency) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="currency"
                            name="currency"
                            defaultValue={trade.currency}
                            type={'text'}
                            label="currency (eg: EUR/USD)"
                            helperText={error.currency}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="currency"
                            name="currency"
                            defaultValue={trade.currency}
                            type={'text'}
                            label="currency (eg: EUR/USD)"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.currency}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="amount">amount</InputLabel>
                            {(error !== '' && error.amount) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="amount"
                            name="amount"
                            defaultValue={trade.amount}
                            type={'text'}
                            label="amount"
                            helperText={error.amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="amount"
                            name="amount"
                            defaultValue={trade.amount}
                            type={'text'}
                            label="amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.amount}
                            </Typography>
                        </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Updating...' : 'Update Trade'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }
    </div>

  )
}

export default EditTrade
