import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';

export default function ListTrades() {
    
    const [trades, settrades] = useState(null);
    const [error, setErros] = useState('');

    const { isLoading: isLoadingtrades, refetch: gettrades } = useQuery(
        "list-admin-trades",
        async () => {
          return await apiClient.get("/api/show-trades");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                settrades(res.data.trades);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }
          },
        }
      );
    
      useEffect(() => {
        gettrades() 
        if (isLoadingtrades) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        
        {isLoadingtrades? 'Loading trades...' : 'List of Trades'}
      </Typography>
    <TableContainer component={Paper}>
        
      {trades !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        
        <TableCell>Trader Name</TableCell>
          <TableCell align="right">Trade Type</TableCell>
          <TableCell align="right">Trade Status</TableCell>
          <TableCell align="right">Trade Amount</TableCell>
          <TableCell align="right">View/Edit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {trades.map((trade) => (
          <TableRow
            key={trade.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {trade.trader.trader_name}
            </TableCell>
            <TableCell align="right">{trade.trade_type}</TableCell>
            <TableCell align="right">{trade.status}</TableCell>
            <TableCell align="right">{trade.amount}</TableCell>
            <TableCell align="right"><Button component={RouterLink} to={`/edit-trade/${trade.id}`} startIcon={<EditIcon />}>View/Edit</Button></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>
    </div>
  );
}