import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';

export default function UserCopies(props) {
    const { copiers } = props;
   
  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        List of copying
      </Typography>
    <TableContainer component={Paper}>
        
      {copiers !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        
        <TableCell>Copier Name</TableCell>
        <TableCell align="right">Trader Copied</TableCell>
        <TableCell align="right">amount</TableCell>
        <TableCell align="right">Profit</TableCell>
          <TableCell align="right">status</TableCell>
          <TableCell align="right">View/Edit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {copiers.map((copier) => (
          <TableRow
            key={copier.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {copier.user.name}
            </TableCell>
            <TableCell align="right">{copier.trader.trader_name}</TableCell>
            <TableCell align="right">{copier.amount}</TableCell>
            <TableCell align="right">{copier.profit}</TableCell>
            <TableCell align="right">{copier.status}</TableCell>
            <TableCell align="right"><Button component={RouterLink} to={`/edit-copier/${copier.id}`} startIcon={<EditIcon />}>View/Edit</Button></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>
    </div>
  );
}