import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    traders: JSON.parse(localStorage.getItem('traders')) || [],
}

export const tradersSlice = createSlice({
    name: 'traders',
    initialState,
    reducers: {
        setTraders: (state, action) =>{
            state.traders = action.payload;  
            localStorage.setItem('traders', JSON.stringify(state.traders));
        }       
    }
})

export const {setTraders} = tradersSlice.actions;
export default tradersSlice.reducer;