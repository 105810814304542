import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const Editcopier = () => {
    const params = useParams();

    const [copier, setequity] = useState(null);
    const { isLoading: isLoadingequity, refetch: getequity } = useQuery(
        "edit-copier",
        async () => {
          return await apiClient.get(`/api/edit-copier/${params.id}`);
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setequity(res.data.copier);
                setPlanForm({ ...planForm,
                    amount : res.data.copier.amount,
                    proportion : res.data.copier.proportion,
                    profit : res.data.copier.profit,
                    floating_profit : res.data.copier.floating_profit,
                    gain : res.data.copier.gain,
                    average_daily : res.data.copier.average_daily,
                    average_month :res.data.copier.average_month,
                    equity :res.data.copier.equity,
                    status :res.data.copier.status,
                 });
            }
          },
          onError: (err) => {
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }
          },
        }
      );

    const dispatch = useDispatch();
    const initialLogin = {
        amount : ''	,
        proportion : ''	,
        profit : ''	,
        floating_profit : ''	,
        equity: '',
        gain : ''	,
        average_daily : ''	,
        average_month : '',
        status : '',
        
    }
    
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);

    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
            const formData = new FormData();
            formData.append('copier_id' , params.id	)
            formData.append('amount' , planForm.amount	)
            formData.append('proportion' , planForm.proportion	)
            formData.append('profit' , planForm.profit	)
            formData.append('floating_profit' , planForm.floating_profit	)
            formData.append('gain' , planForm.gain	)
            formData.append('average_daily' , planForm.average_daily	)
            formData.append('average_month' , planForm.average_month)
            formData.append('equity', planForm.equity)
            formData.append('status', planForm.status)

          return await apiClient.post(`/api/update-copier`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 

useEffect(() => {
        getequity();
      }, []);
  return (
    <div style={{marginTop: '70px'}}>
        {copier === null?
        'Loading copier...'
        :
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        Edit Copier
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to edit this trade where {copier.user.name} with email {copier.user.email} is copying trader with name {copier.trader.trader_name}
                    </Typography>
                    
                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="status">Select copier status</InputLabel>
                      {(error !== '' && error.status) ?
                          <Select
                              error
                              onChange={onChange}
                              labelId="status"
                              id="status"
                              name="status"
                              defaultValue={copier.status}
                              label={`Select copier status`}
                              helperText={error.status}
                              >
                              <MenuItem selected value=''>
                                  Select copier status
                              </MenuItem>

                              <MenuItem selected value='pending'>
                              pending
                              </MenuItem> 

                              <MenuItem selected value='approved'>
                              approved
                              </MenuItem> 
                              <MenuItem selected value='completed'>
                              completed
                              </MenuItem> 
                              
                          </Select>
                      :
                          <Select
                              onChange={onChange}
                              labelId="status"
                              name="status"
                              defaultValue={copier.status}
                              id="status"
                              label={`Select copier status`}
                              >
                              <MenuItem selected value="">
                                  Select copier status
                              </MenuItem>     

                              <MenuItem selected value='pending'>
                              pending
                              </MenuItem> 

                              <MenuItem selected value='approved'>
                              approved
                              </MenuItem> 
                              <MenuItem selected value='completed'>
                              completed
                              </MenuItem>  
                          </Select>
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.status}
                      </Typography>
                  </FormControl>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Amount Invested">Amount Invested</InputLabel>
                            {(error !== '' && error.amount) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="amount"
                            name="amount"
                            defaultValue={copier.amount}
                            type={'text'}
                            label="Amount Invested"
                            helperText={error.amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="amount"
                            name="amount"
                            defaultValue={copier.amount}
                            type={'text'}
                            label="Amount Invested"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.amount}
                            </Typography>
                            
                        </FormControl>
                        
                        
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="proportion">proportion</InputLabel>
                            {(error !== '' && error.proportion) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="proportion"
                            type={'text'}
                            name="proportion"
                            defaultValue={copier.proportion}
                            label="proportion"
                            helperText={error.proportion}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="proportion"
                            type={'text'}
                            name="proportion"
                            defaultValue={copier.proportion}
                            label="proportion"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.proportion}
                            </Typography>
                            
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="profit">Profit</InputLabel>
                            {(error !== '' && error.profit) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="profit"
                            type={'text'}
                            name="profit"
                            defaultValue={copier.profit}
                            label="Profit"
                            helperText={error.profit}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="profit"
                            type={'text'}
                            name="profit"
                            defaultValue={copier.profit}
                            label="Profit"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.profit}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="equity">equity</InputLabel>
                          {(error !== '' && error.equity) ?

                          <OutlinedInput
                          onChange={onChange}
                          error
                          id="equity"
                          type={'text'}
                          name="equity"
                          defaultValue={copier.equity}
                          label="equity"
                          helperText={error.equity}
                          />
                          :
                          <OutlinedInput
                          onChange={onChange}
                          id="equity"
                          type={'text'}
                          name="equity"
                          defaultValue={copier.equity}
                          label="equity"
                          />
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.equity}
                          </Typography>
                      </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="gain">gain</InputLabel>
                            {(error !== '' && error.gain) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="gain"
                            type={'text'}
                            name="gain"
                            defaultValue={copier.gain}
                            label="gain"
                            helperText={error.gain}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="gain"
                            type={'text'}
                            name="gain"
                            defaultValue={copier.gain}
                            label="gain"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.gain}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="floating_profit">Floating Profit</InputLabel>
                            {(error !== '' && error.floating_profit) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="floating_profit"
                            type={'text'}
                            name="floating_profit"
                            defaultValue={copier.floating_profit}
                            label="Floating Profit"
                            helperText={error.floating_profit}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="floating_profit"
                            type={'text'}
                            name="floating_profit"
                            defaultValue={copier.floating_profit}
                            label="Floating Profit"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.floating_profit}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="average_daily">Average Daily</InputLabel>
                            {(error !== '' && error.average_daily) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="average_daily"
                            type={'text'}
                            name="average_daily"
                            defaultValue={copier.average_daily}
                            label="Average Daily"
                            helperText={error.average_daily}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="average_daily"
                            type={'text'}
                            name="average_daily"
                            defaultValue={copier.average_daily}
                            label="Average Daily"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.average_daily}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="average_month">Average Month</InputLabel>
                            {(error !== '' && error.average_month) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="average_month"
                            name="average_month"
                            defaultValue={copier.average_month}
                            type={'text'}
                            label="Average Month"
                            helperText={error.average_month}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="average_month"
                            name="average_month"
                            defaultValue={copier.average_month}
                            type={'text'}
                            label="Average Month"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.average_month}
                            </Typography>
                        </FormControl>                       

                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Updating...' : 'Update copier'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
         }
    </div>
  )
}

export default Editcopier
