import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useSelector } from 'react-redux';
import {Divider} from '@mui/material';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import AddchartIcon from '@mui/icons-material/Addchart';
const Traders = () => {

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const user = useSelector((state) => state.user.user);
    const list = useSelector((state) => state.traders.traders);
     const calcPercentage = (profit, loss) => {
        let total = parseFloat(profit) + parseFloat(loss);
        let percent = profit/total;

        return percent * 100;

     }

     const isCopying = (copiers) => {
        let status = false;
        copiers.forEach(copier => {
            if (parseInt(copier.user_id) === parseInt(user.id)) {
                status = true;
            }
        });

        return status;
     }

  return (
    <div>
        <Typography mb={-8} mt={4} px={3} sx={{ marginTop: '75px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
        Copier Area
        </Typography>
        {list.length > 0 &&
        <Grid container  sx={{ marginTop: '75px'}} px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {list.map(trader =>(
            <Grid key={trader.id} item xs={12} md={4}>
                
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%', paddingX: '10px'}}>
                            {isCopying(trader.followers) &&
                            <Chip mb={-4} sx={{transform: 'translate(110px, -1px)', color: '#07fb07', position: 'relative'}} size="small" icon={<AddchartIcon color='success' sx={{color: '#07fb07'}} />} label="Copying" />
                            }
                <Link  to={`/view-trades/${trader.id}`} component={RouterLink} underline="none" color="inherit">
                    
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '12px', paddingX: '5px'}}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Avatar sx={{ bgcolor: '#d7251e' }} src={trader.image}/>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="subtitle2" component="div">
                                {trader.trader_name}
                            </Typography>

                            <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="caption" component="div">
                                {trader.level}
                            </Typography>
                            </Box>
                        </Box>
                        
                        <Box>
                            
                            {parseInt(trader.risk_score) < 3  &&
                            <Chip label={`${parseInt(trader.risk_score)} Risk`} color="success" /> 
                            }
                            {(parseInt(trader.risk_score) > 2 && parseInt(trader.risk_score) < 5 )&& 
                            <Chip label={`${parseInt(trader.risk_score)} Risk`} color="warning" /> 
                            }
                            {parseInt(trader.risk_score) > 4 &&
                            <Chip label={`${parseInt(trader.risk_score)} Risk`} color="error" /> 
                            }
                        
                        </Box>
                        </Box>
                    <Divider/>


                    <CardContent>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Box>
                                <Typography my={0} variant="caption" color="text.secondary">
                                    Gain
                                </Typography>
                                <Typography sx={{ color: '#09b509', fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                {trader.gain}%
                            </Typography>
                            </Box>

                            <Box>
                                <Typography my={0} variant="caption" color="text.secondary">
                                    Copiers
                                </Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                {trader.copiers}
                            </Typography>
                            </Box>

                            {/* <Box>
                                <Typography my={0} variant="caption" color="text.secondary">
                                    Commission
                                </Typography>
                                <Typography sx={{fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                {trader.commission}%
                            </Typography>
                            </Box> */}
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>
                            <Typography my={0} sx={{textAlign: 'left'}} variant="caption" color="text.secondary">
                                Profit and loss
                            </Typography>

                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'}} gutterBottom variant="subtitle2" component="div">{formatPrice(trader.profit)}</Typography>

                                <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize', display: 'flex', justifyContent: 'space-between'  }} gutterBottom variant="subtitle2" component="div">{formatPrice(trader.loss)} </Typography>                              
                            </Box>
                        </Box> 

                        <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="determinate" value={calcPercentage(trader.profit, trader.loss)} />
                        </Box>
                    </CardContent>
                </Link>    
                </Card>
            </Grid>
            ))}
        </Grid>
        }
    </div>
  )
}

export default Traders
