import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const EditTrader = () => {
    const params = useParams();

    const [trader, settraders] = useState(null);
    const { isLoading: isLoadingtraders, refetch: gettraders } = useQuery(
        "edit-trader",
        async () => {
          return await apiClient.get(`/api/edit-trader/${params.id}`);
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                settraders(res.data.trader);
                setPlanForm({ ...planForm,
                    trader_name : res.data.trader.trader_name,
                    level : res.data.trader.level,
                    min_amount : res.data.trader.min_amount,
                    gain : res.data.trader.gain,
                    copiers:res.data.trader.copiers,
                    commission : res.data.trader.commission,
                    profit : res.data.trader.profit,
                    loss : res.data.trader.loss,
                    risk_score :res.data.trader.risk_score,
                    equity :res.data.trader.equity,
                    duration :res.data.trader.duration,
                    description :res.data.trader.description,
                    floating_profit :res.data.trader.floating_profit,
                    balance :res.data.trader.balance,
                    master_traders_bonus :res.data.trader.master_traders_bonus,
                    leverage :res.data.trader.leverage,
                    max_unrealised_loss :res.data.trader.max_unrealised_loss,
                    max_drawdown_duration :res.data.trader.max_drawdown_duration,
                 });
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

    const dispatch = useDispatch();
    const initialLogin = {
        trader_name : ''	,
        level : ''	,
        min_amount : ''	,
        gain : ''	,
        copiers: '',
        commission : ''	,
        profit : ''	,
        loss : ''	,
        risk_score : '',
        equity : '',
        duration : '',
        description : '',
        floating_profit : '',
        balance : '',
        master_traders_bonus : '',
        leverage : '',
        max_unrealised_loss : '',
        max_drawdown_duration : '',
    }
    
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);

    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });
    const initialImage = {
        image: ''
    }
    const [productImage, setImage] = useState(initialImage);
    const  onFileChange = (event) => { 
        setImage({...productImage, image: event.target.files[0]});
      }; 


    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
            const formData = new FormData();
            formData.append('trader_id' , params.id	)
            formData.append('trader_name' , planForm.trader_name	)
            formData.append('level' , planForm.level	)
            formData.append('min_amount' , planForm.min_amount	)
            formData.append('gain' , planForm.gain	)
            formData.append('commission' , planForm.commission	)
            formData.append('profit' , planForm.profit	)
            formData.append('loss' , planForm.loss	)
            formData.append('risk_score' , planForm.risk_score)
            formData.append('copiers', planForm.copiers)
            formData.append('equity', planForm.equity)
            formData.append('duration', planForm.duration)
            formData.append('description', planForm.description)
            formData.append('floating_profit', planForm.floating_profit)
            formData.append('balance', planForm.balance)
            formData.append('master_traders_bonus', planForm.master_traders_bonus)
            formData.append('leverage', planForm.leverage)
            formData.append('max_unrealised_loss', planForm.max_unrealised_loss)
            formData.append('max_drawdown_duration', planForm.max_drawdown_duration)
            formData.append('image', productImage.image);

          return await apiClient.post(`/api/update-trader`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            
            const event2 = new Event('processed');
            window.dispatchEvent(event2);

            if (err.response?.data.length) {
                let myerror = err.response?.data || err;         
            setErros(myerror.errors)               
            }else{
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
            }    
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 

useEffect(() => {
        gettraders();
      }, []);
  return (
    <div style={{marginTop: '70px'}}>
        {trader === null?
        'Loading Trader...'
        :
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        EDIT TRADERS
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to edit traders
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="level">Select Trader Level</InputLabel>
                      {(error !== '' && error.level) ?
                          <Select
                              error
                              onChange={onChange}
                              labelId="level"
                              id="level"
                              name="level"
                              defaultValue={trader.level}
                              label={`Select Trader Level`}
                              helperText={error.level}
                              >
                              <MenuItem selected value=''>
                                  Select Trader Level
                              </MenuItem>

                              <MenuItem selected value='Legend'>
                              Legend
                              </MenuItem> 

                              <MenuItem selected value='Growing Talent'>
                              Growing Talent
                              </MenuItem> 
                              <MenuItem selected value='Expert'>
                              Expert
                              </MenuItem> 
                              <MenuItem selected value='High Achiever'>
                              High Achiever
                              </MenuItem>              
                              
                          </Select>
                      :
                          <Select
                              onChange={onChange}
                              labelId="level"
                              name="level"
                              defaultValue={trader.level}
                              id="level"
                              label={`Select Trader Level`}
                              >
                              <MenuItem selected value="">
                                  Select Trader Level
                              </MenuItem>     

                              <MenuItem selected value='Legend'>
                              Legend
                              </MenuItem> 

                              <MenuItem selected value='Growing Talent'>
                              Growing Talent
                              </MenuItem> 
                              <MenuItem selected value='Expert'>
                              Expert
                              </MenuItem> 
                              <MenuItem selected value='High Achiever'>
                              High Achiever
                              </MenuItem>  
                          </Select>
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.level}
                      </Typography>
                  </FormControl>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Trader Name">Trader Name</InputLabel>
                            {(error !== '' && error.trader_name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="trader_name"
                            name="trader_name"
                            defaultValue={trader.trader_name}
                            type={'text'}
                            label="Trader Name"
                            helperText={error.trader_name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="trader_name"
                            name="trader_name"
                            defaultValue={trader.trader_name}
                            type={'text'}
                            label="Trader Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.trader_name}
                            </Typography>
                            
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="min_amount">Min Amount</InputLabel>
                            {(error !== '' && error.min_amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="min_amount"
                            type={'text'}
                            name="min_amount"
                            defaultValue={trader.min_amount}
                            label="Min Amount"
                            helperText={error.min_amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="min_amount"
                            type={'text'}
                            name="min_amount"
                            defaultValue={trader.min_amount}
                            label="Min Amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.min_amount}
                            </Typography>
                            
                        </FormControl>

                        {/* <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="level">Level</InputLabel>
                            {(error !== '' && error.level) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="level"
                            type={'text'}
                            name="level"
                            defaultValue={trader.level}
                            label="Level"
                            helperText={error.level}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="level"
                            type={'text'}
                            name="level"
                            defaultValue={trader.level}
                            label="Level"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.level}
                            </Typography>
                        </FormControl> */}

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="copiers">Copiers</InputLabel>
                          {(error !== '' && error.copiers) ?

                          <OutlinedInput
                          onChange={onChange}
                          error
                          id="copiers"
                          type={'text'}
                          name="copiers"
                          defaultValue={trader.copiers}
                          label="Copiers"
                          helperText={error.copiers}
                          />
                          :
                          <OutlinedInput
                          onChange={onChange}
                          id="copiers"
                          type={'text'}
                          name="copiers"
                          defaultValue={trader.copiers}
                          label="Copiers"
                          />
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.copiers}
                          </Typography>
                      </FormControl>

                        {/* <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="commission">Commission</InputLabel>
                            {(error !== '' && error.commission) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="commission"
                            type={'text'}
                            name="commission"
                            defaultValue={trader.commission}
                            label="Commission"
                            helperText={error.commission}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="commission"
                            type={'text'}
                            name="commission"
                            defaultValue={trader.commission}
                            label="Commission"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.commission}
                            </Typography>
                            
                        </FormControl> */}

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="gain">Gain</InputLabel>
                            {(error !== '' && error.gain) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="gain"
                            type={'text'}
                            name="gain"
                            defaultValue={trader.gain}
                            label="Gain"
                            helperText={error.gain}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="gain"
                            type={'text'}
                            name="gain"
                            defaultValue={trader.gain}
                            label="Gain"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.gain}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="profit">Profit</InputLabel>
                            {(error !== '' && error.profit) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="profit"
                            type={'text'}
                            name="profit"
                            defaultValue={trader.profit}
                            label="Profit"
                            helperText={error.profit}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="profit"
                            type={'text'}
                            name="profit"
                            defaultValue={trader.profit}
                            label="Profit"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.profit}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="loss">Loss</InputLabel>
                            {(error !== '' && error.loss) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="loss"
                            type={'text'}
                            name="loss"
                            defaultValue={trader.loss}
                            label="Loss"
                            helperText={error.loss}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="loss"
                            type={'text'}
                            name="loss"
                            defaultValue={trader.loss}
                            label="Loss"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.loss}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="risk_score">Risk Score</InputLabel>
                            {(error !== '' && error.risk_score) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="risk_score"
                            name="risk_score"
                            defaultValue={trader.risk_score}
                            type={'text'}
                            label="Risk Score"
                            helperText={error.risk_score}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="risk_score"
                            name="risk_score"
                            defaultValue={trader.risk_score}
                            type={'text'}
                            label="Risk Score"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.risk_score}
                            </Typography>
                        </FormControl>

                        {/* <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="equity">Equity</InputLabel>
                            {(error !== '' && error.equity) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="equity"
                            name="equity"
                            defaultValue={trader.equity}
                            type={'text'}
                            label="Equity"
                            helperText={error.equity}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="equity"
                            name="equity"
                            defaultValue={trader.equity}
                            type={'text'}
                            label="Equity"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.equity}
                            </Typography>
                        </FormControl> */}

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="duration">Duration</InputLabel>
                            {(error !== '' && error.duration) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="duration"
                            name="duration"
                            defaultValue={trader.duration}
                            type={'text'}
                            label="Duration"
                            helperText={error.duration}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="duration"
                            name="duration"
                            defaultValue={trader.duration}
                            type={'text'}
                            label="Duration"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.duration}
                            </Typography>
                        </FormControl>

                        {/* <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="description">Description</InputLabel>
                            {(error !== '' && error.description) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="description"
                            name="description"
                            defaultValue={trader.description}
                            type={'text'}
                            label="Description"
                            helperText={error.description}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="description"
                            name="description"
                            defaultValue={trader.description}
                            type={'text'}
                            label="Description"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.description}
                            </Typography>
                        </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="floating_profit">Floating Profit</InputLabel>
                            {(error !== '' && error.floating_profit) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="floating_profit"
                            name="floating_profit"
                            defaultValue={trader.floating_profit}
                            type={'text'}
                            label="Floating Profit"
                            helperText={error.floating_profit}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="floating_profit"
                            name="floating_profit"
                            defaultValue={trader.floating_profit}
                            type={'text'}
                            label="Floating Profit"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.floating_profit}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="balance">Balance</InputLabel>
                            {(error !== '' && error.balance) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="balance"
                            name="balance"
                            defaultValue={trader.balance}
                            type={'text'}
                            label="Balance"
                            helperText={error.balance}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="balance"
                            name="balance"
                            defaultValue={trader.balance}
                            type={'text'}
                            label="Balance"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.balance}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="master_traders_bonus">Master Trader Bonus</InputLabel>
                            {(error !== '' && error.master_traders_bonus) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="master_traders_bonus"
                            name="master_traders_bonus"
                            defaultValue={trader.master_traders_bonus}
                            type={'text'}
                            label="Master Trader Bonus"
                            helperText={error.master_traders_bonus}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="master_traders_bonus"
                            name="master_traders_bonus"
                            defaultValue={trader.master_traders_bonus}
                            type={'text'}
                            label="Master Trader Bonus"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.master_traders_bonus}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="leverage">Leverage</InputLabel>
                            {(error !== '' && error.leverage) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="leverage"
                            name="leverage"
                            defaultValue={trader.leverage}
                            type={'text'}
                            label="Leverage"
                            helperText={error.leverage}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="leverage"
                            name="leverage"
                            defaultValue={trader.leverage}
                            type={'text'}
                            label="Leverage"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.leverage}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="max_unrealised_loss">Max Unrealised Loss</InputLabel>
                            {(error !== '' && error.max_unrealised_loss) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="max_unrealised_loss"
                            name="max_unrealised_loss"
                            defaultValue={trader.max_unrealised_loss}
                            type={'text'}
                            label="Max Unrealised Los"
                            helperText={error.max_unrealised_loss}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="max_unrealised_loss"
                            name="max_unrealised_loss"
                            defaultValue={trader.max_unrealised_loss}
                            type={'text'}
                            label="Max Unrealised Los"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.max_unrealised_loss}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="max_drawdown_duration">Max Drawdown Duration</InputLabel>
                            {(error !== '' && error.max_drawdown_duration) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={1} multiline
                            id="max_drawdown_duration"
                            name="max_drawdown_duration"
                            defaultValue={trader.max_drawdown_duration}
                            type={'text'}
                            label="Max Drawdown Duration"
                            helperText={error.max_drawdown_duration}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={1} multiline
                            id="max_drawdown_duration"
                            name="max_drawdown_duration"
                            defaultValue={trader.max_drawdown_duration}
                            type={'text'}
                            label="Max Drawdown Duration"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.max_drawdown_duration}
                            </Typography>
                        </FormControl> */}


                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Upload Trader Image
                        </Typography>
                        <Box>
                        {productImage.image !== '' &&
                            <img style={{width: "180px", height: "auto"}} src={URL.createObjectURL(productImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.product_image) ?

                            <OutlinedInput
                            onChange={onFileChange}
                            error
                            accept="image/*"
                            id="product_image"
                            type='file'
                            name="product_image"
                            helperText={error.product_image}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onFileChange}
                            id="product_image"
                            type='file'
                            name="product_image"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.product_image}
                            </Typography>
                        </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Updating...' : 'Update Trader'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
         }
    </div>
  )
}

export default EditTrader
