import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import {MiniChart, ForexCrossRates } from "react-ts-tradingview-widgets";
import Markets from '../Home/Markets';

const MarketsAccount = () => {
  const [coinSymbol, setCoinSymbol] = useState('BINANCE:BTCUSDT');
  return (
    <div style={{marginTop: '75px'}}>
      <Card sx={{height: "480px", marginTop: '65px', borderRadius: '15px', overflow: 'hidden'}}>
        <CardContent>
            <ForexCrossRates 
                locale= "en"
                colorTheme= "dark"
                isTransparent= {true}
                height={400}
                autosize= {false}
                width="100%"/>
        </CardContent>
      </Card>

      <Markets/>
    </div>
  )
}

export default MarketsAccount
